import axios from "axios";


const axiosreq = axios.create({
    baseURL: process.env.VUE_APP_ENDPOINT ? process.env.VUE_APP_ENDPOINT : '/api/',
  withCredentials: true,
});

export const Login = async (data) => {
  try {
    const req = await axiosreq({ url: "/v1/auth/login", method: "post", data: data });
    return req;
  } catch (error) {
  }
};

export const Register = async (data) => {
  try {
    const req = await axiosreq({ url: "/v1/auth/register", method: "post", data: data });
    return req;
  } catch (error) {
    if (error.response.status === 401) {
    }
  }
};

export const Logout = async () => {
  try {
    const req = await axiosreq({ url: "/v1/auth/logout", method: "post" });
    return req;
  } catch (error) {
    if (error.response.status === 401) {
    }
  }
};

export const resetPassword = async (data, id) => {
  try {
    const req = await axiosreq({ url: `/v1/auth/reset-password/{id}/${id}`, method: "put" });
    return req;
  } catch (error) {
    if (error.response.status === 401) {
    }
  }
};
