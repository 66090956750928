export const arrayToTree = (arr) => {
  const map = arr.reduce((map, item) => {
    map[item.id] = item;
    return map;
  }, {});

  const buildTree = (parent) => {
    const children = arr.filter((item) => item.id_menu_induk === parent.id);
    if (children.length) {
      parent.submenu = children.map((item) => buildTree(item));
      parent.expand = false;
    }
    return parent;
  };

  return arr.filter((item) => !item.id_menu_induk).map((item) => buildTree(item));
};
