import { createApp } from "vue";
import defaultLogo from '@/assets/logo-maluku-malut.png'
import App from "./App.vue";
import router from "./router";
import PrimeVue from "primevue/config";
import Dropdown from "primevue/dropdown";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import Password from "primevue/password";
import Notifications from "notiwind";
import RadioButton from 'primevue/radiobutton';
import ConfirmationService from "primevue/confirmationservice";
import UploadGambar from "@/views/components/UploadGambar.vue";
import LandingpageGambar from "@/views/components/LandingpageGambar.vue";
import { createI18n } from "vue-i18n";
import Languange from "./views/components/Languange";
import CKEditor from "@ckeditor/ckeditor5-vue";
import "./index.css";
import "flowbite";

const i18n = new createI18n({
  locale: "id",
  messages: Languange,
  legacy: false,
  warnHtmlMessage: false,
});

const app = createApp(App);
app.use(i18n);
app.use(router);
app.use(PrimeVue);
app.use(ConfirmationService);
app.use(Notifications);
app.use(CKEditor);
app.mount("#app");
app.component("Dropdown", Dropdown);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("Button", Button);
app.component("Checkbox", Checkbox);
app.component("Password", Password);
app.component("RadioButton", RadioButton);
app.component("UploadGambar", UploadGambar);
app.component("LandingpageGambar", LandingpageGambar);

app.directive('img', {
  mounted(el, binding) {
    el.addEventListener('error', () => {
      el.src = binding.value === 'logo' ? defaultLogo : defaultLogo
    })
  }
})

app.config.globalProperties.$baseUrl = process.env.VUE_APP_ENDPOINT;
