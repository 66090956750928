import axios from "axios";

const axiosreq = axios.create({
    baseURL: process.env.VUE_APP_ENDPOINT ? process.env.VUE_APP_ENDPOINT : '/api/',
});

export const getInformasiDasar = async () => {
  try {
    const req = await axiosreq({
      url: "/v1/landing-page/find/informasiDasar",
      method: "get",
    });
    return req;
  } catch (error) {}
};

export const getBerita = async () => {
  try {
    const req = await axiosreq({
      url: "/v1/landing-page/findAll/berita",
      method: "get",
    });
    return req;
  } catch (error) {}
};

export const getBeritaById = async (id_berita) => {
  try {
    const req = await axiosreq({
      url: `/v1/landing-page/findOne/berita/${id_berita}`,
      method: "get",
    });
    return req;
  } catch (error) {}
};

export const get3Berita = async () => {
  try {
    const req = await axiosreq({
      url: `/v1/landing-page/findAllForLandingPage/berita`,
      method: "get",
    });
    return req;
  } catch (error) {}
};

// Penghargaan

export const getPenghargaan = async () => {
  try {
    const req = await axiosreq({
      url: "/v1/landing-page/findAll/penghargaan",
      method: "get",
    });
    return req;
  } catch (error) {}
};

// Karir

export const getKarir = async () => {
  try {
    const req = await axiosreq({
      url: "/v1/landing-page/findAll/career",
      method: "get",
    });
    return req;
  } catch (error) {}
};

export const getKarirById = async (id) => {
  try {
    const req = await axiosreq({
      url: `/v1/landing-page/findAll/career/${id}`,
      method: "get",
    });
    return req;
  } catch (error) {}
};

export const getMitra = async () => {
  try {
    const req = await axiosreq({
      url: "/v1/landing-page/findAll/mitra",
      method: "get",
    });
    return req;
  } catch (error) {}
};

export const getRegulator = async () => {
  try {
    const req = await axiosreq({
      url: "/v1/landing-page/findAll/regulator",
      method: "get",
    });
    return req;
  } catch (error) {}
};

export const getSlider = async () => {
  try {
    const req = await axiosreq({
      url: "/v1/landing-page/findAll/slide",
      method: "get",
    });
    return req;
  } catch (error) {}
};

export const getLayanan = async () => {
  try {
    const req = await axiosreq({
      url: "/v1/landing-page/findAll/layanan",
      method: "get",
    });
    return req;
  } catch (error) {}
};

export const getLayananById = async (id) => {
  try {
    const req = await axiosreq({
      url: `/v1/landing-page/findOne/layanan/${id}`,
      method: "get",
    });
    return req;
  } catch (error) {}
};

export const getLaporan = async (jenis) => {
  try {
    const req = await axiosreq({
      url: `/v1/landing-page/findAll/laporan/${jenis}`,
      method: "get",
    });
    return req;
  } catch (error) {}
};
export const getTentang = async () => {
  try {
    const req = await axiosreq({
      url: `/v1/landing-page/findAll/tentangPerusahaan`,
      method: "get",
    });
    return req;
  } catch (error) {}
};
export const getLogo = async () => {
  try {
    const req = await axiosreq({
      url: `/v1/landing-page/findAll/logo`,
      method: "get",
    });
    return req;
  } catch (error) {}
};
export const getPejabat = async (jenis_jabatan) => {
  try {
    const req = await axiosreq({
      url: `/v1/landing-page/findAll/pejabat/${jenis_jabatan}`,
      method: "get",
    });
    return req;
  } catch (error) {}
};
export const getStruktur = async () => {
  try {
    const req = await axiosreq({
      url: `/v1/landing-page/findAll/struktur`,
      method: "get",
    });
    return req;
  } catch (error) {}
};
export const getPengumuman = async () => {
  try {
    const req = await axiosreq({
      url: `/v1/landing-page/findAll/pengumuman`,
      method: "get",
    });
    return req;
  } catch (error) {}
};
export const getPengumumanById = async (id) => {
  try {
    const req = await axiosreq({
      url: `/v1/landing-page/findAll/pengumuman/${id}`,
      method: "get",
    });
    return req;
  } catch (error) {}
};
export const getProdukByJenis = async (jenis_bisnis) => {
  try {
    const req = await axiosreq({
      url: `/v1/landing-page/findAll/jenisBisnis/${jenis_bisnis}`,
      method: "get",
    });
    return req;
  } catch (error) {}
};
export const getIsiProdukById = async (jenis) => {
  try {
    const req = await axiosreq({
      url: `/v1/landing-page/findAll/isiBisnis/${jenis}`,
      method: "get",
    });
    return req;
  } catch (error) {}
};
export const getSukubungaByJenis = async (jenis_suku_bunga) => {
  try {
    const req = await axiosreq({
      url: `/v1/landing-page/findAll/suku-bunga/${jenis_suku_bunga}`,
      method: "get",
    });
    return req;
  } catch (error) {}
};
export const getAllSukuBunga = async () => {
  try {
    const req = await axiosreq({
      url: `/v1/landing-page/findAll/jenis_suku_bunga`,
      method: "get",
    });
    return req;
  } catch (error) {}
};
export const getPengadaan = async () => {
  try {
    const req = await axiosreq({
      url: `/v1/landing-page/findAll/tender`,
      method: "get",
    });
    return req;
  } catch (error) {}
};
export const getPengadaanById = async (id) => {
  try {
    const req = await axiosreq({
      url: `/v1/landing-page/findAll/tender/${id}`,
      method: "get",
    });
    return req;
  } catch (error) {}
};
// EBANKING
export const getEbanking = async () => {
  try {
    const req = await axiosreq({
      url: `/v1/landing-page/findAll/ebanking`,
      method: "get",
    });
    return req;
  } catch (error) {}
};
export const getEbankingById = async (id) => {
  try {
    const req = await axiosreq({
      url: `/v1/landing-page/findAll/ebanking/${id}`,
      method: "get",
    });
    return req;
  } catch (error) {}
};
// KANTOR
export const getKantorByJenisKantor = async (jenis_kantor) => {
  try {
    const req = await axiosreq({
      url: `/v1/landing-page/findAll/kantor/${jenis_kantor}`,
      method: "get",
    });
    return req;
  } catch (error) {}
};
// LOKASI ATM
export const getLokasiAtmByJenis = async (jenis) => {
  try {
    const req = await axiosreq({
      url: `/v1/landing-page/findAll/lokasiAtm/${jenis}`,
      method: "get",
    });
    return req;
  } catch (error) {}
};
export const getMenu = async () => {
  try {
    const req = await axiosreq({
      url: `/v1/landing-page/findAll/menu`,
      method: "get",
    });
    return req;
  } catch (error) {}
};

export const getGambarByJenis = async (jenis) => {
  try {
    const req = await axiosreq({ url: `/v1/landing-page/findAll/jenis_gambar/${jenis}`,
      method: "get",
    });
    return req;
  } catch (error) {
    return error;
  }
};
