export default {
  id: {
    message: {
      lihat: "Lihat",
      tahun: "Tahun",
      hubungi_kami: "Hubungi Kami",
      header1: "Bank Maluku Malut",
      header2: "Setia Melayani",
      subheader: "Pastikan Simpanan dan Bisnis anda Pada Bank Maluku Malut",
      eksplor: "Eksplor",
      card_center: "Card Centre",
      card_center_det:
        "Jasa perbankan yang dapat Anda andalkan untuk menjalankan dan mengembangkan bisnis Anda.",
      layanan_digital: "Layanan Digital",
      layanan_digital_det:
        "Jalur kilat 1x24 jam untuk memanfaatkan berbagai produk kredit serta rekening simpanan.",
      lokasi: "Lokasi",
      lokasi_det:
        "Kami hadir dimanapun Anda membutuhkan, ATM, Cabang dan titik pelayanan lainnya.",
      layanan_label: "LAYANAN",
      layanan_header: "Layanan<br/>Digital Kami",
      berita_label: "BERITA",
      berita_header: "Berita Perusahaan",
      pengumuman_label: "Pengumuman",
      aplikasi: "Dapatkan aplikasi  <br/> mobile kami",
      berita_btn: "Berita Lainnya",
      aplikasi_det:
        "Dapatkan aplikasi mobile kami untuk pelayanan yang cepat dan terbaik",
      alasan_label: "BANK MALUKU MALUT",
      alasan_header:
        'Kenapa Memilih<br />Bank <span class="text-blue-maluku underline underline-offset-8">Maluku Malut ?</span>',
      mitra: "Mitra Kerja",
      regulator: "Regulator",
      bantuan: "Butuh Bantuan atau Pertanyaan Lainnya?",
      bantuan_btn: "Kirimi Kami Pesan",
      footer_kontak: "Kontak",
      footer_navigasi: "Menu",
      footer_layanan: "Layanan",
      beranda: "Beranda",
      perusahaan: "Perusahaan",
      informasi: "Informasi",
      produk: "Produk",
      ebanking: "E-banking",
      simulasi: "Simulasi",
      jaringan_kantor: "Jaringan Kantor",
      karir: "Karir",
      berita: "Berita",
      tender: "Pengadaan Barang & Jasa",
      tender_desc:
        "Untuk mengatur fungsi perencanaan, pelaksanaan dan pengawasan pengadaan barang/jasa agar sesuai dengan fungsi, hak dan kewajiban serta mempercepat proses pemenuhan kebutuhan barang/jasa  sehingga dalam pelaksanaannya dapat dilakukan secara efisien, efektif, transparan serta akuntabel",
      tentang1: "Tentang",
      tentang2: "Perusahaan",
      sejarah1: "Sejarah",
      sejarah2: "Kami",
      visi1: "Visi",
      visi2: "Kami",
      misi1: "Misi",
      misi2: "Kami",
      tatakelola: "Tata Kelola",
      tatakelola1: "Tata",
      tatakelola2: " Kelola",
      investor: "Hubungan Investor",
      investor1: "Hubungan",
      investor2: "Investor",
      dirut1: "Direktur",
      dirut2: "Perusahaan",
      eksekutif1: "Pejabat",
      eksekutif2: "Eksekutif",
      komisaris1: "Dewan",
      komisaris2: "Komisaris",
      komisaris3: "Dewan Komite",
      e4001: "Kamu berada di tempat yang salah!",
      e4002:
        "Tidak menemukan yang kamu cari? Ambil waktu sebentar dan mulai dari beranda kami.",
      e400btn: "kembali ke beranda",
      kosong: "Belum ada data",
      simpanan: "Simpanan",
      bisnis: "Simpanan",
      tidakadadata: "Tidak Ada Data",
      namalaporan: "Nama Laporan",
      laporankeuangan: "Laporan Keuangan Berkelanjutan",
      bungasimpanan: "Bunga Simpanan",
      bungapinjaman: "Bunga Pinjaman",
      sbdk: "Suku Bunga Dasar Kredit",
      laporankeuanganberkelanjutan: "Laporan Keuangan Berkelanjutan",
      pinjaman: "Pinjaman",
      pinjaman2: "Bunga Pinjaman",
      layanan: "Layanan",
      laporan: "Laporan",
      laporan_tahunan: "Laporan Tahunan",
      laporan_triwulan: "Laporan Triwulan",
      laporan_bulanan: "Laporan Bulanan",
      laporan_tata_kelola: "Laporan Tata Kelola",
      penilaian_tata_kelola: "Penilaian Tata Kelola",
      laporan_pelaksana: "Laporan Pelaksana",
      // Footer
      telp: "Telepon",
      alamat: "Alamat",
      kurs: "Kurs",
      // Sejarah
      sejarah: "Sejarah",
      artilogo: "Arti Logo",
      visimisi: "Visi dan Misi",
      strukturorganisasi: "Struktur Organisasi",
      belumadastruktur:
        "Jabatan yang belum memiliki atasan/belum memiliki bawahan",
      kepemilikansaham: "Kepemilikan Saham",
      dewankomisaris: "Dewan Komisaris",
      direksi: "Direksi",
      eksekutif: "Eksekutif",
      // Jaringan Kantor
      kantorpusat: "Kantor Pusat",
      kantorcabang: "Kantor Cabang",
      kantorcabangpembantu: "Kantor Cabang Pembantu",
      kantorkas: "Kantor Kas",
      lokasiatm: "Lokasi ATM",
      titikpembayaran: "Titik Pembayaran",
      mobilkaskeliling: "Mobil Kas Keliling",
      jaringan: "Jaringan",
      kantor: "Kantor",
      // Simulasi
      simulasikpr: "Simulasi KPR",
      simulasikredit: "Simulasi Kredit",
      simulasideposito: "Simulasi Deposito",
      simulasitabungan: "Simulasi Tabungan",
      // Sukubunga
      sukubunga: "Suku Bunga",
      // Penghargaan
      penghargaan: "Penghargaan",
      sukubungakurs: "Kurs",
      csr: "CSR",
    },
  },
  en: {
    message: {
      lihat: "Show",
      tahun: "Year",
      hubungi_kami: "Contact Us",
      header1: "Bank Maluku Malut",
      header2: "Serve Faithfully",
      subheader: "Make sure your savings and business at Bank Maluku Malut",
      eksplor: "Explore",
      card_center: "Card Centre",
      card_center_det:
        "Banking services that you can count on to run and grow your business.",
      layanan_digital: "Digital Services",
      layanan_digital_det:
        "Express route 1x24 hours to take advantage of various credit products and savings accounts.",
      lokasi: "Location",
      lokasi_det:
        "We are here wherever you need, ATMs, branches and other service points.",
      layanan_label: "SERVICES",
      layanan_header: "Our<br/>Digital Services",
      berita_label: "NEWS",
      berita_header: "Company News",
      perusahaan: "Company",
      pengumuman_label: "Announcement",
      berita_btn: "More",
      aplikasi: "Get our mobile  <br/> Application.",
      aplikasi_det: "Get our mobile app for the best and fastest services.",
      alasan_label: "BANK MALUKU MALUT",
      alasan_header:
        'Why Choose<br />Bank <span class="text-blue-maluku underline underline-offset-8">Maluku Malut ?</span>',
      mitra: "Partners",
      regulator: "Regulators",
      bantuan: "Need Help or Any Questions?",
      bantuan_btn: "Send Us a Message",
      footer_kontak: "Contact",
      footer_navigasi: "Links",
      footer_layanan: "Services",
      beranda: "Home",
      informasi: "Informations",
      produk: "Products",
      ebanking: "E-banking",
      simulasi: "Simulations",
      jaringan_kantor: "Office Network",
      karir: "Careers",
      berita: "News",
      tender: "Procurement of Goods & Services",
      tender_desc:
        "To regulate the function of planning, implementing and supervising the procurement of goods/services so that they are in accordance with the functions, rights and obligations as well as accelerating the process of meeting the needs of goods/services ",
      tentang1: "About",
      tentang2: "Company",
      sejarah1: "Our",
      sejarah2: "History",
      visi1: "Our",
      visi2: "Visions",
      misi1: "Our",
      misi2: "Missions",
      investor: "Investor Relations",
      tatakelola: "Governance",
      tatakelola1: "Governance",
      tatakelola2: "",
      investor1: "Investor",
      investor2: "Relations",
      dirut1: "Company",
      dirut2: "Directors",
      eksekutif1: "Executives",
      eksekutif2: "",
      komisaris1: "Board of",
      komisaris2: "Commissioners",
      komisaris3: "Dekom Committee",
      e4001: "Oops! you’re on the wrong place.",
      e4002:
        "Can't find what you need? Take a moment and start from our Homepage.",
      e400btn: "back to Home",
      kosong: "Empty data!",
      simpanan: "Savings",
      bisnis: "Business",
      tidakadadata: "Data Not Found",
      namalaporan: "Report Name",
      laporankeuangan: "Sustainable Finance Report",
      bungasimpanan: "Deposit Interest",
      sbdk:"Prime Lending Rate",
      laporankeuanganberkelanjutan: "Sustainable Financial Reports",
      pinjaman: "Loan",
      layanan: "Service",
      laporan: "Report",
      laporan_tahunan: "Annually Report",
      laporan_triwulan: "Quarterly Report",
      laporan_bulanan: "Monthly Report",
      laporan_tata_kelola: "Governance Report",
      penilaian_tata_kelola: "Governance Assessment",
      laporan_pelaksana: "Governance Executive Report",
      // Footer
      telp: "Telephone",
      alamat: "Address",
      kurs: "Kurs Calculate",
      // Sejarah
      sejarah: "History",
      artilogo: "Logo Meaning",
      visimisi: "Vision and Mission",
      strukturorganisasi: "Organizational Structure",
      belumadastruktur: "Positions that doesnt have superior/subordinate yet. ",
      kepemilikansaham: "Shareholding",
      dewankomisaris: "Board of Commissioners",
      direksi: "Management",
      eksekutif: "Executive",
      // Jaringan Kantor
      kantorpusat: "Head Office",
      kantorcabang: "Branch Offices",
      kantorcabangpembantu: "Sub-Branch Offices",
      kantorkas: "Cash Office",
      lokasiatm: "ATM Location",
      titikpembayaran: "Payment Point",
      mobilkaskeliling: "Mobile Cash Cars",
      jaringan: "Network",
      kantor: "Office",
      // Simulasi
      simulasikpr: "KPR Simulation",
      simulasikredit: "Credit Simulation",
      simulasideposito: "Deposit Simulation",
      simulasitabungan: "Savings Simulation",
      // Sukubunga
      sukubunga: "Interest",
      penghargaan: "Appreciation",
      bungapinjaman: "Loan Interest",
      sukubungakurs: "Exchange Rate",
      csr: "CSR",
    },
  },
};
