<template>
  <div class="flex space-x-2 items-center">
    <div>
      <p v-if="!image" class="text-xs text-gray-600 md:text-right">Maksimum gambar 2MB.</p>
      <button @click="toggle" v-else class="text-xs text-gray-600 flex items-center space-x-2 md:justify-end hover:scale-105 duration-200">
        <span>{{ image }}</span> <span class="pi pi-external-link text-xs"> </span>
      </button>
      <OverlayPanel ref="op" class="border"> 
        <img :src="`${$baseUrl}/gambar/${image}`" class="max-w-[300px]" :alt="image" />  
      </OverlayPanel>
    </div>
    <FileUpload class="button-primary upload-no-icon" mode="basic" :customUpload="true" :multiple="false" :chooseLabel="label" :auto="true" @uploader="onUploadImg" :accept="accept" />
  </div>
</template>

<script>
import { getGambar, postGambar } from "@/api/GambarApi";
import { onMounted, ref } from "vue";
import FileUpload from "primevue/fileupload";
import { notify } from "notiwind";
import OverlayPanel from "primevue/overlaypanel";
export default {
  props: ["label", "tipe", "accept"],
  components: { FileUpload, OverlayPanel },
  setup(props) {
    const image = ref(null);
    const op = ref();

    onMounted(() => getImage());

    const getImage = async () => {
      const result = await getGambar(props.tipe);
      if ([401].includes(result?.response?.status)) {
        router.push({ name: "logout" });
      } else {
        image.value = result?.data?.data?.foto;
      }
    };

    const onUploadImg = async (event) => {
      if (event.files[0].size <= 2000000) {
        const param = { foto: event.files[0], jenis_gambar: props.tipe };
        const result = await postGambar(param);
        if ([401].includes(result?.response?.status)) {
          router.push({ name: "logout" });
        } else {
          if ([201, 200].includes(result.status)) {
            if (result?.data?.success) {
              notify({ group: "success", title: "Sukses", text: result.data.message }, 4000);
              getImage();
            } else {
              notify({ group: "error", title: "Gagal", text: result.data.message }, 4000);
            }
          } else {
            notify({ group: "error", title: "Gagal", text: "Data gagal disimpan!" }, 4000);
          }
        }
      } else {
        notify({ group: "error", title: "Gagal", text: "Ukuran file melebihi batas maksimal" }, 4000);
      }
    };

    return { onUploadImg, image };
  },
  methods: {
    toggle(event) {
      this.$refs.op.toggle(event);
    },
  },
};
</script>

