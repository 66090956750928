<template>
  <router-view></router-view>
</template>

<script>
export default {};
</script>

<style scoped>
:deep .p-datatable {
  @apply !text-xs;
}

</style>
