<template>
  <img v-if="image" class="hover:scale-105 duration-500  mt-5" :src="`${$baseUrl}/gambar/${image}`" />
  <img v-else class="hover:scale-105 duration-500  mt-5" src="@/assets/images/kantor.png" />
</template>

<script>
import { onMounted, ref } from "vue";
import { getGambarByJenis } from "@/api/LandingPageApi";
export default {
  props: ["tipe"],
  setup(props) {
    const image = ref(null);
    onMounted(() => getImage());

    const getImage = async () => {
      const result = await getGambarByJenis(props.tipe);
      if (result.data?.data) image.value = result?.data?.data?.foto;
    };
    return { image };
  },
};
</script>

<style></style>
