<template>
  <div v-if="$route.meta.requiresAuth">
    <NotificationGroup group="success">
      <div class="fixed inset-0 flex items-start justify-end p-6 px-4 py-6 pointer-events-none z-[60]">
        <div class="w-full max-w-sm">
          <Notification
            v-slot="{ notifications }"
            enter="transform ease-out duration-300 transition"
            enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
            enter-to="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-500"
            leave-from="opacity-100"
            leave-to="opacity-0"
            move="transition duration-500"
            move-delay="delay-300"
          >
            <div class="flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-white rounded-lg shadow-2xl" v-for="notification in notifications" :key="notification.id">
              <div class="flex items-center justify-center w-12 bg-green-800">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <div class="px-4 py-2 -mx-3">
                <div class="mx-3">
                  <span class="font-semibold text-green-800">{{ notification.title }}</span>
                  <p class="text-sm text-gray-800">{{ notification.text }}</p>
                </div>
              </div>
            </div>
          </Notification>
        </div>
      </div>
    </NotificationGroup>
    <NotificationGroup group="error">
      <div class="fixed inset-0 flex items-start justify-end p-6 px-4 py-6 pointer-events-none z-[60]">
        <div class="w-full max-w-sm">
          <Notification
            v-slot="{ notifications }"
            enter="transform ease-out duration-300 transition"
            enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
            enter-to="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-500"
            leave-from="opacity-100"
            leave-to="opacity-0"
            move="transition duration-500"
            move-delay="delay-300"
          >
            <div class="flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-white rounded-md shadow-2xl" v-for="notification in notifications" :key="notification.id">
              <div class="flex items-center justify-center w-12 bg-red-800">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <div class="px-4 py-2 -mx-3">
                <div class="mx-3">
                  <span class="font-semibold text-red-800">{{ notification.title }}</span>
                  <p class="text-sm text-gray-600">{{ notification.text }}</p>
                </div>
              </div>
            </div>
          </Notification>
        </div>
      </div>
    </NotificationGroup>
    <div class="md:flex min-h-screen">
      <aside class="absolute md:static z-[40] bg-birulangitmaluku h-full md:h-auto w-full md:w-80 border-r duration-700 md:duration-75 ease-in" :class="[open ? 'left-0' : 'left-[-100%]']">
        <div class="p-4 flex justify-between">
          <img src="@/assets/logo-maluku-malut.png" alt="" />
          <span @click="sidebarOpen" class="flex md:hidden text-blue-maluku">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </span>
        </div>
        <div class="py-7">
          <div v-for="(link, i) in menu" :key="i" class="relative">
            <router-link v-if="!link.id_menu_induk && link.link !== 'false'" :to="{ name: link.link }">
              <div :class="$route.name === link.link ? 'bg-biru/10 text-blue-maluku border-r-blue-maluku border-r-4 pl-5' : 'text-biru'" class="ease-in-out duration-200 p-3 hover:bg-biru/10">
                <div class="flex items-center space-x-2">
                  <span v-html="link.icon"></span>
                  <span>{{ link.nama_menu }}</span>
                </div>
              </div>
            </router-link>
            <div v-else>
              <div
                @click="
                  () => {
                    link.expand = !link.expand;
                  }
                "
                class="cursor-pointer p-3 text-biru hover:bg-biru/10"
              >
                <div class="flex justify-between items-center">
                  <div class="flex items-center space-x-2">
                    <span v-html="link.icon"></span>
                    <span>{{ link.nama_menu }}</span>
                  </div>
                  <div :class="link.expand ? 'rotate-90' : ''" class="ease-in-out duration-200">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                  </div>
                </div>
              </div>
              <div v-if="link?.submenu" :class="link.expand ? ' rounded-md w-full z-10 shadow-tabungan' : ' hidden'">
                <div v-for="sub in link.submenu" class="border-l-2 ml-4">
                  <div v-if="sub.jenis_menu === 'submenu'">
                    <router-link :to="{ name: sub.link }">
                      <div :class="$route.name === sub.link ? 'bg-biru/10 text-blue-maluku border-r-blue-maluku border-r-4' : 'text-biru'" class="p-3 hover:bg-biru/10">
                        <span>{{ sub.nama_menu }}</span>
                      </div>
                    </router-link>
                  </div>
                  <div v-else-if="sub.jenis_menu === 'subdropdown'">
                    <div
                      @mouseenter="
                        () => {
                          sub.expand = true;
                        }
                      "
                      @mouseleave="
                        () => {
                          sub.expand = false;
                        }
                      "
                      class="relative cursor-pointer p-3 text-biru hover:bg-biru/10"
                    >
                      <div class="flex justify-between">
                        <div class="flex items-center space-x-2">
                          <span>{{ sub.nama_menu }}</span>
                        </div>
                      </div>
                      <div v-if="sub.submenu" :class="sub.expand ? 'flex' : 'hidden'" class="md:absolute md:left-[100%] duration-200 ease-in-out md:top-1/2 md:-translate-y-1/2">
                        <div class="bg-white shadow-layanan rounded-md border w-60">
                          <div v-for="subsub in sub.submenu" class="border-b">
                            <router-link v-if="subsub?.link" :to="{ name: subsub?.link }">
                              <div :class="$route.name === subsub.link ? 'bg-biru/10 text-blue-maluku border-r-blue-maluku border-r-4' : 'text-biru'" class="p-3 hover:bg-biru/10">
                                <span>{{ subsub.nama_menu }}</span>
                              </div>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
      <div class="w-full bg-white">
        <nav class="z-[40] w-full p-4 px-7 bg-birulangitmaluku border-b">
          <div class="flex justify-between items-center">
            <div class="flex items-center space-x-2 md:space-x-0">
              <span @click="sidebarOpen" class="text-blue-maluku flex md:hidden">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
              </span>
              <div class="md:flex hidden">
                <button @click="$router.go(-1)" class="hover:scale-105 ease-in-out duration-200 text-blue-maluku mr-4">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                  </svg>
                </button>
              </div>
              <h1 class="text-blue-maluku text-md md:text-xl font-semibold">{{ $route.meta.menu }}</h1>
            </div>
            <div class="flex space-x-4 items-center relative">
              <h1 class="hidden md:flex text-blue-maluku font-semibold">{{ storage.nama }}</h1>
              <div @click="() => (showToggle = !showToggle)" class="cursor-pointer w-10 h-10 rounded-full bg-blue-maluku relative">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                </svg>
              </div>
              <transition name="fade">
                <div v-if="showToggle" class="absolute -bottom-20 delay-100 duration-200 ease-in-out right-0 z-40">
                  <ul class="list-nostyle bg-white w-60 border border-gray-100">
                    <li
                      @click="
                        () => {
                          showToggle = !showToggle;
                        }
                      "
                      class="py-2 px-4 shadow-tabungan border-b rounded-md hover:bg-birulangitmaluku text-biru md:text-blue-maluku cursor-pointer"
                    >
                      <router-link :to="{ name: 'cms-profil' }">
                        <div class="flex space-x-2 items-center">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                            </svg>
                          </span>
                          <span>Profil</span>
                        </div>
                      </router-link>
                    </li>
                    <li @click="logout" class="py-2 px-4 shadow-tabungan rounded-md hover:bg-birulangitmaluku text-biru md:text-blue-maluku cursor-pointer">
                      <div class="flex space-x-2 items-center">
                        <span
                          ><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                          </svg>
                        </span>
                        <span>Logout</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
          </div>
        </nav>
        <router-view v-slot="{ Component, route }">
          <div class="flex md:hidden px-7 pt-7">
            <button @click="$router.go(-1)" class="hover:scale-105 ease-in-out duration-200 text-blue-maluku mr-4">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
              </svg>
            </button>
          </div>
          <Transition name="fade">
            <div :key="route.name">
              <component :is="Component"></component>
            </div>
          </Transition>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { Logout } from "../../api/AuthApi";
import { notify } from "notiwind";
import { useRoute, useRouter } from "vue-router";
import { onMounted } from "@vue/runtime-core";
import { getAksesByUser } from "@/api/MenuApi";
import { arrayToTree } from "@/views/components/ArrToTree";
export default {
  setup() {
    const storage = ref({ ...window.localStorage });
    const dropdownNum = ref("");
    const dropdownStat = ref(false);
    const subHovered = ref("");
    const subStat = ref(false);
    const showToggle = ref(false);
    const open = ref(false);
    const router = useRouter();
    const route = useRoute();
    const menu = ref([]);

    onMounted(() => {
      if (window.localStorage.getItem("id") && route.params !== "blank") getSidebarMenu();
    });

    const getSidebarMenu = async () => {
      const result = await getAksesByUser(window.localStorage.getItem('id'));
      if ([201, 200].includes(result.status)) {
        if (result?.data?.data) {
          const res = result?.data?.data.filter((item) => item.id != null);
          menu.value = arrayToTree(res.sort((a, b) => a.posisi - b.posisi));
        }
      }
    };

    const sidebarOpen = () => {
      open.value = !open.value;
    };

    const logout = async () => {
      const result = await Logout();
      router.push({ name: "logout" });
    };

    return { open, sidebarOpen, storage, showToggle, logout, menu };
  },
};
</script>

<style scoped>
.fade-enter-active {
  transition: all 0.2s ease;
  transition-delay: 300ms;
}

.fade-leave-active {
  transition: all 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

:deep .p-datatable {
  @apply !text-xs;
}
</style>
