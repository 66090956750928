import axios from "axios";

const axiosreq = axios.create({
  baseURL: process.env.VUE_APP_ENDPOINT ? process.env.VUE_APP_ENDPOINT : '/api/',
  withCredentials: true,
});

axiosreq.defaults.headers.common["id_user"] = window.localStorage.getItem("id");
axiosreq.defaults.headers.post["id_user"] = window.localStorage.getItem("id");
// axiosreq.defaults.headers.common["Authorization"] = `Bearer ${window.localStorage.getItem("token")}`;

export const getAksesByUser = async (id_user) => {
  try {
    const req = await axiosreq({
      url: `/v1/menu/sidebar/${id_user}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    });
    return req;
  } catch (error) {
    return error;
  }
};

export const getMenu = async () => {
  try {
    const req = await axiosreq({
      url: "/v1/menu",
      method: "get",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    });
    return req;
  } catch (error) {
    return error;
  }
};

export const getMenuById = async (id) => {
  try {
    const req = await axiosreq({
      url: `/v1/menu/${id}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    });
    return req;
  } catch (error) {
    return error;
  }
};

export const postMenu = async (data) => {
  try {
    const req = await axiosreq({
      url: "/v1/menu",
      method: "post",
      data: data,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    });
    return req;
  } catch (error) {
    return error;
  }
};

export const putMenu = async (data, id) => {
  try {
    const req = await axiosreq({
      url: `/v1/menu/${id}`,
      method: "put",
      data: data,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    });
    return req;
  } catch (error) {
    return error;
  }
};

export const deleteMenu = async (id) => {
  try {
    const req = await axiosreq({
      url: `/v1/menu/${id}`,
      method: "delete",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    });
    return req;
  } catch (error) {
    return error;
  }
};
