import axios from "axios";

const axiosreq = axios.create({
  baseURL: process.env.VUE_APP_ENDPOINT ? process.env.VUE_APP_ENDPOINT : "/api/",
  withCredentials: true,
});

axiosreq.defaults.headers.common["id_user"] = window.localStorage.getItem("id");
axiosreq.defaults.headers.post["id_user"] = window.localStorage.getItem("id");

export const getGambar = async (jenis) => {
  try {
    const req = await axiosreq({
      url: `/v1/kantor/gambar/upload/${jenis}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    });
    return req;
  } catch (error) {
    return error;
  }
};

export const postGambar = async (data) => {
  try {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    const req = await axiosreq({
      url: "/v1/kantor/gambar/upload",
      method: "post",
      data: formData,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    });
    return req;
  } catch (error) {
    return error;
  }
};
