<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>

<script>
import { computed, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import Blank from "./views/layout/Blank.vue";
import Dashboard from "./views/layout/Dashboard.vue";

export default {
  setup() {
    const route = useRoute()
    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.meta.layout === "blank") return Blank;
      return Dashboard;
    });

    return { resolveLayout };
  },
};
</script>
